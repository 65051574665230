<script>
  import { fade } from "svelte/transition";
  import { onMount } from 'svelte';
  const STATE = {
    send: {
      success: null,
      error: null,
    },
    error: null,
    isSent: window.localStorage.cookieConsent !== undefined,
  };
  async function handleSubmit(event) {
    const URLparams = prepareAjaxParams();
    try {
      const rawResponse = await fetch(
        "/bitrix/services/main/ajax.php?" + URLparams,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      );
      const content = await rawResponse.json();
      if (content.status === "success") {
        STATE.send.success = true;
        STATE.isSent = true;
        localStorage.setItem("cookieConsent", true);
      } else {
        throw content.errors ? content.errors : "Неизвестная ошибка";
      }
    } catch (err) {
      STATE.isSent = true;
      localStorage.setItem("cookieConsent", true);
      STATE.send.error = "Произошла неизвестная ошибка. Попробуйте позже.";
    }
  }

  function prepareAjaxParams() {
    const query = {
      c: "dvash:cookie.consent",
      action: "main",
      mode: "ajax",
      agreementId: window.laserguru.cookieConsentParams.id,
       sessid: BX.message("bitrix_sessid")
    };
    return new URLSearchParams(Object.entries(query)).toString();
  }
</script>
{#if !STATE.isSent}
  <div class="cookie-consent" transition:fade>
    {@html window.laserguru.cookieConsentParams.AGREEMENT_LABEL}
    <button class="cookie-consent__button-submit" on:click={handleSubmit}>
      Принимаю
    </button>
  </div>
{/if}

<style>
  .cookie-consent {
    justify-content: space-between;
    align-items: flex-end;
    background-color: #ff6b01;
    position: fixed;
    z-index: 99999;
    bottom: 15px;
    max-width: 90%;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
    display: flex;
    color: #fff;
    margin-left: 5%;
	align-items:center;
  }
  .cookie-consent__button-submit {
    max-width: 15em;
    padding: 0.5em;
    font-size: 1em;
    margin-left: 1.5rem;
    background-color: transparent;
    color: #fff;
	outline: none;
	border:2px solid white
  }
  .cookie-consent a {
    text-decoration: underline;
    color: #fff;
  }
</style>
